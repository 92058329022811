import type { Component } from "solid-js";
import styled from "@suid/material/styles/styled";

const BrandLogo: Component<{ logo: string; }> = ( props ) => {
	const Logo = styled( 'img' )( {
		width: '100%',
		maxWidth: '240px',
		maxHeight: '64px'
	} );

	return (
		<Logo src={ props.logo } />
	);
};

export default BrandLogo;
