import Solid from "solid-js";
import {
	Drawer,
	Divider,
	List,
	ListSubheader,
	ListItem,
	ListItemIcon,
	ListItemButton,
	ListItemText,
} from "@suid/material";
import Home from "@suid/icons-material/Home";
import { useService } from "solid-services";
import PasswordDlg from "../../../services/PasswordDialog";
import type { Props } from "../Types";
import Box from "../container/Box";

const DrawerContainer: Solid.Component<Solid.ParentProps<Props>> = (props) => {
	const dialog = useService(PasswordDlg);
	const dismiss = props.toggle(false);
	const openDialog = dialog().setDialog(true);

	return (
		<Drawer onClose={props.toggle(false)} open={props.drawer()}>
			<Box>
				{props.children}

				<List>
					<Divider />

					<ListItemButton
						component="a"
						href="https://care4youhealth.com/"
					>
						<ListItem>
							<ListItemIcon>
								<Home />
							</ListItemIcon>
							<ListItemText>Care4You Health</ListItemText>
						</ListItem>
					</ListItemButton>

					<Divider />
					<ListSubheader>Site Navigation</ListSubheader>

					{props.items.map((menu) => (
						<ListItemButton>
							<ListItem>
								<ListItemText>{menu.caption}</ListItemText>
							</ListItem>
						</ListItemButton>
					))}
				</List>
			</Box>
		</Drawer>
	);
};

export default DrawerContainer;
