import type { Component, ParentProps } from "solid-js";
import { Container, AppBar, Toolbar } from "./styles";
import type { Props } from "./Types";

export const Header: Component<ParentProps<Props>> = ({ children }) => (
	<AppBar>
		<Container maxWidth="xl" disableGutters>
			<Toolbar>{children}</Toolbar>
		</Container>
	</AppBar>
);

export default Header;
