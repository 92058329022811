import type { Component } from "solid-js";
import Box from "@suid/material/Box";
import BrandLogo from "../../components/BrandLogo";
import DrawerContainer from "./container/DrawerContainer";
import type { Props } from "./Types";

const Drawer: Component<Props> = (props) => (
	<DrawerContainer {...props}>
		<Box px={2} pt={2} pb={0}>
			<BrandLogo logo={props.theme.logo} />
		</Box>
	</DrawerContainer>
);

export default Drawer;
