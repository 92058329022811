import type { Component, ParentProps } from "solid-js";
import { Box, Button } from "@suid/material";
import MenuIcon from "@suid/icons-material/Menu";
import BrandLogo from "../BrandLogo";
import { MenuBox } from "./styles";

type Props = ParentProps<{
	logo: string;
	toggle: (value: boolean) => VoidFunction;
}>;

const AppBarLogo: Component<Props> = (props) => (
	<Box sx={{ display: "flex", flexGrow: { xs: 0, lg: 1 } }}>
		<MenuBox>
			<Button
				size="large"
				variant="text"
				sx={{ color: "black" }}
				onClick={props.toggle(true)}
			>
				<MenuIcon />
			</Button>
		</MenuBox>
		<BrandLogo logo={props.logo} />
	</Box>
);

export default AppBarLogo;
