import type { Component } from "solid-js";
import { Stack, Box, Button, Grid } from "@suid/material";
import Lock from "@suid/icons-material/Lock";
import type { SiteTheme } from "../../providers/ThemeProvider";
import type { MenuItem as MenuEntry } from "./Types";
import { ResponsiveGrid } from "./styles";
import PasswordDlg from "../../services/PasswordDialog";
import { useService } from "solid-services";
import type { Slot } from "../../helpers/Slot";

export type Props = {
	theme: SiteTheme;

	slots?: Slot<"button" | "items">;
	items?: Array<MenuEntry>;
};

const MergeMenuEntries = (entries: Array<MenuEntry> = []): Array<MenuEntry> => {
	const defaults: Array<MenuEntry> = [
		{ caption: "Home", href: "https://care4youhealth.com/" },
	];

	const [entry] = entries,
		[defEntry] = defaults;

	if (entry) {
		const needsHomeLink: boolean =
			(entry as MenuEntry).href.localeCompare(
				(defEntry as MenuEntry).href,
			) !== 0 &&
			(entry as MenuEntry).caption.localeCompare(
				(defEntry as MenuEntry).caption,
			) !== 0;

		return (needsHomeLink && defaults.concat(entries)) || entries;
	} else return defaults;
};

const AppBarMenu: Component<Props> = (props) => {
	const dialog = useService(PasswordDlg);

	return (
		<Box sx={{ display: { xs: "none", lg: "flex" } }}>
			<ResponsiveGrid direction="column" container>
				<Grid
					sx={{ display: "flex", px: 3, pt: 2 }}
					justifyContent="flex-end"
					item
				>
					{(props?.slots && props.slots.button) || (
						<Button
							onClick={dialog().setDialog(true)}
							color="primary"
							variant="contained"
							size="small"
						>
							<Lock />
							Sign in
						</Button>
					)}
				</Grid>
				<Grid item>
					<Stack direction={"row"}>
						{MergeMenuEntries(props.items || []).map(
							({ href, caption }: MenuEntry) => (
								<Button
									sx={{
										"&": {
											color: "black",
										},
									}}
									variant="text"
									size="medium"
									href={href}
								>
									{caption}
								</Button>
							),
						)}
					</Stack>
				</Grid>
			</ResponsiveGrid>
		</Box>
	);
};

export default AppBarMenu;
