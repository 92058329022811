import { createTheme } from '@suid/material';
import { grey } from '@suid/material/colors';

const theme = createTheme( {
	palette: {
		background: {
			default: grey[ 200 ],
		}
	},

	typography: {
		h1: {
			fontSize: '250%',
		},
		h2: {
			fontSize: '200%',
		},
		h3: {
			fontSize: '175%',
		},
		h4: {
			fontSize: '150%',
		},
		h5: {
			fontSize: '125%',
		},
		h6: {
			fontSize: '100%',
		}
	},

	components: {
		MuiIcon: {
			defaultProps: {
				sx: {
					fontSize: '1rem'
				}
			}
		},
		MuiButton: {
			defaultProps: {
				variant: 'outlined',
			}
		}
	}
} );

export default theme;
