import type { Component, ParentProps } from "solid-js";
import { CssBaseline, ThemeProvider } from "@suid/material";
import theme from "../../app/theme";

import "layouts/ShopifyBase/material.css";

export const Layout: Component<ParentProps> = ({ children }) => (
	<ThemeProvider theme={theme}>
		<CssBaseline />
		{children}
	</ThemeProvider>
);
