import Grid from "@suid/material/Grid";
import ToolbarBase from "@suid/material/Toolbar";
import ContainerBase from "@suid/material/Container";
import Box from "@suid/material/Box";
import AppBarBase from "@suid/material/AppBar";
import blueGrey from "@suid/material/colors/blueGrey";
import styled from "@suid/material/styles/styled";

type StyledAppBar = typeof AppBarBase;
type StyledContainer = typeof ContainerBase;
type StyledToolbar = typeof ToolbarBase;
type StyledGrid = typeof Grid;
type StyledBox = typeof Box;

export const AppBar: StyledAppBar = styled( AppBarBase )( ( {
	position: 'fixed',
	boxShadow: 'none',
	backgroundImage: 'none',
	backgroundColor: blueGrey[ 50 ],
	overflowY: 'hidden',
	padding: '1rem 0'
} ) );

export const Container: StyledContainer = styled( ContainerBase )( ( {
	position: 'relative'
} ) );

export const Toolbar: StyledToolbar = styled( ToolbarBase )( ( {
	position: 'relative',
	backgroundColor: 'transparent',
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	flexShrink: 0,
	borderRadius: 0,
	maxHeight: '5.5rem'
} ) );

export const ResponsiveGrid: StyledGrid = styled( Grid )( ( { theme } ) => ( {
	display: 'none',
	[ theme.breakpoints.up( 'xl' ) ]: {
		display: 'flex'
	}
} ) );

export const MenuBox: StyledBox = styled( Box )( ( { theme } ) => ( {
	display: 'flex',
	[ theme.breakpoints.up( 'xl' ) ]: {
		display: 'none'
	}
} ) );
